import React,{useEffect,useState} from 'react'
import style from "./Configuration.module.css"

const Configuration = () => {
  const [selectedTab,setSelectedTab]=useState(0)
  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Configurations</p>
        </div>
      </div>
      <div className={style.sections}>
        <div className={style.tabHeader}></div>
      </div>
    </div>
  )
}

export default Configuration